import { changeValueProdct } from '@cannect/utils/valueWithDiscount'
import { formatReal } from 'utils/formatters'
import * as Styles from './styles'

export interface PromotionalPriceProps {
  old_price?: number
  new_price: number
  discount?: string
  discount_type?: any
}

function PromotionalPrice({ discount, new_price, old_price, discount_type }: PromotionalPriceProps) {
  return (
    <Styles.ItemBox className="unit_value">
      {!!discount && !!old_price && (
        <Styles.PromotionalBox>
          <span className="discount">
            {changeValueProdct({
              discount_value: +discount,
              discount_type
            })}
          </span>{' '}
          <span className="old_price">{formatReal(old_price)}</span>
        </Styles.PromotionalBox>
      )}
      {formatReal(new_price)}
    </Styles.ItemBox>
  )
}

export default PromotionalPrice
