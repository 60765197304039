import { cannectDefaultProduct, cannectDefaultProductNoTarg } from 'assets/img'
import CardQuantity from 'components/CardQuantity'
import ModalEditProduct from 'components/ModalEditProdruct'
import useMediaQuery from 'hooks/useMediaQuery'
import { usePrepareCart } from 'hooks/usePrepareCart'
import useSignedUser from 'hooks/useSignedUser'
import { useTokenApi } from 'hooks/useTokenApi'
import PromotionalPrice from 'new-components/MarketPlace/PromotionalPrice'
import { SelectFlavorModal } from 'new-components/SelectFlavorModal'
import { SelectedProductDetailsTypes } from 'new-components/SelectFlavorModal/types'
import { useEffect, useState } from 'react'
import { RiEdit2Line } from 'react-icons/ri'
import api from 'services/api'
import { MOBILE_WIDTH } from 'utils/constants'
import { formatPriceToNumber } from 'utils/formatters'
import * as Style from './styles'

interface PropsProduct {
  product_id: number
  amount: number
  unitPrice: number
  quantity: number
  image: string
  name: string
  id: number
  product: any
  promotion: any
  stock?: number
  main_product_id?: number
  class_id?: number
}

interface ItemPropProduct {
  product: PropsProduct
  isNotEditing?: boolean
  isNotEditingFlavor?: boolean
}

export function CardProduct({ product, isNotEditing, isNotEditingFlavor }: ItemPropProduct) {
  const { id, quantity, product_id, unitPrice } = product
  const [flavorModal, setFlavorModal] = useState(false)
  const { removeCart, incrementOrDecrementCart, isUpdate, AddToCartByFlavor } = usePrepareCart()
  const [openModal, setOpenModal] = useState(false)
  const { signedUser } = useSignedUser()
  const [loadingOnSave, setLoadingOnSave] = useState(false)
  const [hasManyFlavors, setHasManyFlavors] = useState<any[]>([])
  const { tokenApi } = useTokenApi()

  const isPatientUserType = signedUser?.registration_type === 1

  const imagePath = product?.product?.media
    ? product?.product?.media.filter((value: any) => value.format === 'IMG').sort((a: any, b: any) => a.id - b.id)
    : product?.image
  const flavors = product?.product?.flavors || []
  const flavorSelected = flavors?.find((value: any) => value.id === product?.product.id)

  async function handleSelect(newQuantity: any) {
    if (newQuantity === 0) {
      removeCart(id)
      return
    }
    incrementOrDecrementCart(id, newQuantity)

    // Atualiza a quantidade nos sabores
    const updatedFlavors = hasManyFlavors.map((flavor) => {
      if (flavor.id === product.product_id) {
        return { ...flavor, quantity: newQuantity }
      }
      return flavor
    })
    setHasManyFlavors(updatedFlavors)
  }

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const total = product?.amount ? product.amount : 0
  const defaultImage = product?.product?.supplier?.id === 18 ? cannectDefaultProduct : cannectDefaultProductNoTarg
  const selectedImage = isPatientUserType && !(product?.product?.class_id == 6) ? defaultImage : imagePath[0].path

  const lowStockWarning =
    flavors.length > 0
      ? flavors.find((flavor: any) => flavor.id === product?.product?.id)?.balance <= 0
      : product?.product?.stock <= 0

  const defaultSelectedFlavor: SelectedProductDetailsTypes = {
    id: product?.product_id || product?.id,
    image: product?.image,
    name: product?.product?.translated_name || product?.name,
    stock: product?.product?.stock || product?.stock,
    real_price: product?.unitPrice || product.product?.unitPrice,
    main_product_id: product?.product?.main_product_id || product?.product_id || product?.id,
    quantity: product?.quantity
  }
  const handleSaveFlavor = (items: any[]) => {
    setLoadingOnSave(true)
    AddToCartByFlavor(items)
  }

  const handleOk = () => {
    setLoadingOnSave(false)
    setFlavorModal(false)
  }

  useEffect(() => {
    if (!isNotEditingFlavor) {
      getProductFlavors(product?.product?.main_product_id || product?.product_id || product?.id)
    }
  }, [product])

  const getProductFlavors = async (id?: string | number) => {
    try {
      const { data } = await api.get(`/get_product_flavors/${id}`)
      if (data?.success) {
        setHasManyFlavors(data?.productFlavors)
      }
    } catch (e) {
      console.log('Erro na busca de sabores: ', e)
    }
  }

  const handleCloseFlavorModal = () => {
    // Outras ações podem ser adicionadas aqui
    setFlavorModal(false)
  }

  return (
    quantity > 0 && (
      <Style.Container key={product_id} isMobile={isMobile}>
        <Style.ProductInfos isMobile={isMobile}>
          <img src={selectedImage} alt="foto produto" />
          <Style.ProductName isMobile={isMobile}>{product?.product?.translated_name}</Style.ProductName>
        </Style.ProductInfos>
        <Style.ProductProperties isMobile={isMobile}>
          <Style.Quantity>
            {!isNotEditing ? (
              <>
                <CardQuantity
                  defaultQuantity={quantity}
                  onCardQuantity={(quantity) => handleSelect(quantity)}
                  removeCart={() => removeCart(id)}
                />
                <button
                  style={{
                    opacity: isUpdate ? '.5' : '1',
                    cursor: isUpdate ? 'wait' : 'pointer'
                  }}
                  className="remove-button"
                  onClick={() => {
                    if (!isUpdate) {
                      removeCart(id)
                    }
                  }}>
                  remover
                </button>
              </>
            ) : (
              <div className="qtd">{quantity}</div>
            )}
          </Style.Quantity>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            {lowStockWarning && (
              <Style.DeliveryWarning>
                Possível maior tempo <br /> de entrega
              </Style.DeliveryWarning>
            )}
            {hasManyFlavors.length >= 1 ? (
              <Style.ProductSabor isMobile={isMobile} onClick={() => setFlavorModal(true)}>
                {product?.product?.flavor || 'Natural'}
                <RiEdit2Line />
              </Style.ProductSabor>
            ) : (
              <Style.ProductSabor isMobile={isMobile}>{product?.product?.flavor || 'Natural'}</Style.ProductSabor>
            )}
          </div>

          <Style.ProductPrice isMobile={isMobile}>
            {isMobile && <span>VALOR UNITÁRIO</span>}
            <PromotionalPrice
              new_price={product?.promotion?.promotional_price || unitPrice}
              old_price={product?.promotion?.regular_price}
              discount={product?.promotion?.discount_value}
              discount_type={product?.promotion?.discount_type}
            />
          </Style.ProductPrice>
          <Style.ProductTotal isMobile={isMobile}>
            {isMobile && <span>VALOR TOTAL</span>}
            {formatPriceToNumber(total)}
          </Style.ProductTotal>
        </Style.ProductProperties>
        <ModalEditProduct
          flavorSelected={flavorSelected}
          isOpen={openModal}
          closeModal={() => setOpenModal(false)}
          product={product}
          flavors={flavors}
        />

        <SelectFlavorModal
          open={flavorModal}
          handleCancel={handleCloseFlavorModal}
          handleOk={() => handleOk()}
          defaultSelected={defaultSelectedFlavor}
          onSave={(items: any) => handleSaveFlavor(items)}
          loadingOnSave={loadingOnSave}
          isAuthenticated={!!tokenApi}
          imageSelector={(isAuthenticated, defaultSelected) => {
            if (!isAuthenticated) return 'path/to/public/default/image.jpg'
            return (
              defaultSelected.image ||
              (isPatientUserType ? defaultImage : 'path/to/alternative/authenticated/image.jpg')
            )
          }}
        />
      </Style.Container>
    )
  )
}
