import { formatPrice } from './formatters'

export type formatTextDiscount = {
  discount_value: number
  discount_type: 'value' | 'percent'
}

export function changeValueProdct({ discount_value, discount_type }: formatTextDiscount) {
  if (discount_type === 'percent') {
    return `-${Math.trunc(discount_value)}%`
  }
  return `-R$${Math.trunc(discount_value)}`
}
